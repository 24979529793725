export const PLAYCONSTANTS = {
  songs: [{ al: { name: "", picUrl: "" }, ar: [{ name: "" }] }],
};

export const COMMENT = {
  hotComments: [],
  comments: [],
  userId: 0,
  topComments: [],
};
